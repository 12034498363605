import get from 'lodash/get';

import { findPageByLocation } from '../application';

export function mainContentClassForLocaction(location) {
    const currentPage = findPageByLocation(location.pathname);
    const wrapperClass = get(currentPage, 'wrapperCssClass', 'menu');

    return ['section', 'main-content', wrapperClass].join(' ');
}
