import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';

export const defaultApiParams = {
    app_id: 'RzfCrUYxszvwhpyH2NYb',
    app_code: '28PcMPNBejT6gES8deUtwA',
    country: 'CYP',
    language: 'en',
};

export const geoSuggestions = query => {
    const apiBaseUrl = '//autocomplete.geocoder.api.here.com/6.2/suggest.json';
    const params = {
        ...cloneDeep(defaultApiParams),
        query: query,
        language: 'en',
        resultType: 'houseNumber',
        maxResults: 10,
    };

    return axios.get(apiBaseUrl, { params });
};

export const isValidSuggestion = (city, countryCode, matchLevel) => {
    return matchLevel === 'houseNumber' && countryCode === 'CYP';
};

export const filterSuggestions = suggestionsResponse => {
    return suggestionsResponse.filter(({ address: { city }, countryCode, matchLevel }) => {
        return isValidSuggestion(city, countryCode, matchLevel);
    });
};

export const geocodeLocation = locationId => {
    const apiBaseUrl = '//geocoder.api.here.com/6.2/geocode.json';
    const params = {
        ...cloneDeep(defaultApiParams),
        locationid: locationId,
    };

    return axios.get(apiBaseUrl, { params });
};

export const addtionalDatakeyMap = {
    CountryName: 'countryName',
    CountyName: 'countyName',
};

export function normalizeGeocoderResponse(apiResponse) {
    const location = get(apiResponse, 'Response.View[0].Result[0].Location');

    if (!location) {
        return {};
    }

    const { Address, DisplayPosition, NavigationPosition, LocationId } = location;

    if (!Address || !DisplayPosition || !NavigationPosition || !NavigationPosition[0] || !LocationId) {
        return {};
    }

    const { AdditionalData, City: city, HouseNumber: streetNumber, Street: street, PostalCode: zip, Country: country, County: county, Label: label } = Address;
    const { Latitude: dispLat, Longitude: dispLng } = DisplayPosition;
    const { Latitude: navLat, Longitude: navLng } = NavigationPosition[0];

    let additionalData = {};

    if (AdditionalData) {
        AdditionalData.forEach(({ key, value }) => {
            const newKey = addtionalDatakeyMap[key];
            if (newKey) {
                additionalData[newKey] = value;
            }
        });
    }

    const address = [street, streetNumber].filter(Boolean).join(' ');

    return {
        ...cloneDeep(additionalData),
        address,
        street,
        streetNumber,
        zip,
        city,
        country,
        county,
        label,
        locationId: LocationId,
        dispLat,
        dispLng,
        navLat,
        navLng,
    };
}
