export const defaultMessage = 'must be checked';

export const defaultConfig = {
    message: defaultMessage,
    expectation: true,
};

export function equalityValidator(value, config = defaultConfig) {
    const errors = [];

    if (!config) {
        return errors;
    }

    const { message, expectation } = config;

    if (typeof expectation === 'undefined') {
        return errors;
    }

    const errorMessage = message || defaultMessage;

    if (value !== expectation) {
        errors.push(errorMessage);
    }

    return errors;
}
