'use strict';

import React from 'react';

import { Icon } from './icon';

export const ModalCloseButton = ({ closeHandler }) => {
    return (
        <a className="modal-close-button" aria-label="close" href="#" onClick={closeHandler}>
            <Icon icon="fa fa-times-circle" />
        </a>
    );
};

export const Modal = ({ children, closeHandler }) => {
    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={closeHandler} />
            {children}
        </div>
    );
};
