'use strict';

import React from 'react';

import { ShoppingCartPage } from './pages/shopping-cart';
import { DeliveryThankYouPage } from './pages/static/delivery-thank-you-page';
import { AboutPage } from './pages/static/about-page';
import { FaqPage } from './pages/static/faq-page';
import { LegalPage } from './pages/static/legal-page';
import { ContactPage } from './pages/static/contact-page';
import { NewsletterPage } from './pages/newsletter';
import { MenuSelectionPage } from './pages/menu-selection';
import { LandingPageLayout } from './layouts/landing-page';

export const Sitemap = [
    {
        path: '/cart',
        component: ShoppingCartPage,
        pageName: 'cart',
        wrapperCssClass: 'cart',
        exact: true,
    },
    {
        path: '/delivery',
        component: DeliveryThankYouPage,
        pageName: 'delivery',
        wrapperCssClass: 'delivery',
        exact: true,
    },
    {
        path: '/about',
        component: AboutPage,
        pageName: 'about',
        wrapperCssClass: 'about',
        exact: true,
    },
    {
        path: '/faq',
        component: FaqPage,
        pageName: 'faq',
        wrapperCssClass: 'faq',
        exact: true,
    },
    {
        path: '/legal',
        component: LegalPage,
        pageName: 'legal',
        wrapperCssClass: 'legal',
        exact: true,
    },
    {
        path: '/contact',
        component: ContactPage,
        pageName: 'contact',
        wrapperCssClass: 'contact',
        exact: true,
    },
    {
        path: '/newsletter',
        component: NewsletterPage,
        layout: LandingPageLayout,
        pageName: 'newsletter',
        wrapperCssClass: 'newsletter',
        exact: true,
    },
    {
        path: '/:menuItem?',
        component: MenuSelectionPage,
        pageName: 'menu',
        wrapperCssClass: 'menu',
        exact: false,
    },
];
