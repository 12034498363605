export const Actions = {
    putAddress: 'PUT_ADDRESS',
    updateAddress: 'UPDATE_ADDRESS',
    resetAddress: 'RESET_ADDRESS',
    validateAddressForm: 'VALIDATE_ADDRESS_FORM',
    validateOrder: 'VALIDATE_ORDER',
    setCartItemAmount: 'SET_CART_ITEM_AMOUNT',
    setMenuFilter: 'SET_MENU_FILTER',
    setAcceptTos: 'SET_ACCEPT_TOS',
    setSubscribeNewsletter: 'SET_SUBSCRIBE_NEWSLETTER',
    setDispatchOrder: 'SET_DISPATCH_ORDER',
    fetchProducts: 'FETCH_PRODUCTS',
    putProducts: 'PUT_PRODUCTS',
    locationChanged: 'LOCATION_CHANGED'
};
