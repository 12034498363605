export const defaultConfig = {
    min: 1,
    max: null,
};

export function isValueLengthy(value) {
    return typeof value === 'string' || Array.isArray(value);
}

export function buildMessage(min, max) {
    const hasMin = typeof min === 'number';
    const hasMax = typeof max === 'number';

    if (hasMin && hasMax) {
        return `length must be between ${min} and ${max}`;
    } else if (hasMin) {
        return `length must be at least ${min}`;
    } else if (hasMax) {
        return `must not be longer than ${max}`;
    }
}

export function isValidLength(length, min, max) {
    const hasMin = typeof min === 'number';
    const hasMax = typeof max === 'number';

    if (hasMin && hasMax) {
        return length >= min && length <= max;
    } else if (hasMin) {
        return length >= min;
    } else if (hasMax) {
        return length <= max;
    }

    return true;
}

export function lengthValidator(value, config = defaultConfig) {
    const errors = [];

    if (!config || !isValueLengthy(value)) {
        return errors;
    }

    const { message, min, max } = config;

    if (!min && !max) {
        return errors;
    }

    const errorMessage = message || buildMessage(min, max);
    const isValid = isValidLength(value.length, min, max);

    if (!isValid) {
        errors.push(errorMessage);
    }

    return errors;
}
