'use strict';

import React from 'react';

import { StaticPage } from './static-page';
import { SocialMediaLink } from '../../components/social-media-link';

export const ContactPage = () => {
    return (
        <StaticPage page="contact">
            <div className="content">
                <h1>Talk to us</h1>

                <p>
                    If you have any questions, complaints or suggestions please
                    do not hesitate to
                </p>

                <ul>
                    <li>
                        <SocialMediaLink
                            url="mailto:ms@delishios.com"
                            icon="fas fa-envelope"
                            text="Contact us by email"
                        />
                    </li>
                </ul>

                <p>Subscribe to our newsletter and be the first to know when we come to your city:</p>

                <ul>
                    <li>
                        <SocialMediaLink
                            url="https://newsletter.delishios.com/signup"
                            icon="fas fa-newspaper"
                            text="Subscribe to our Newsletter"
                        />
                    </li>
                </ul>

                <p>Join the conversation on social media:</p>

                <ul>
                    <li>
                        <SocialMediaLink
                            url="https://twitter.com/delishios_food"
                            icon="fab fa-twitter"
                            text="Twitter"
                        />
                    </li>
                    <li>
                        <SocialMediaLink
                            url="https://instagram.com/delishios.food"
                            icon="fab fa-instagram"
                            text="Instagram"
                        />
                    </li>
                    <li>
                        <SocialMediaLink
                            url="https://www.facebook.com/delishios.food"
                            icon="fab fa-facebook"
                            text="Facebook"
                        />
                    </li>
                    <li>
                        <SocialMediaLink
                            url="https://www.pinterest.com/delishios"
                            icon="fab fa-pinterest"
                            text="Pinterest"
                        />
                    </li>
                </ul>
            </div>
        </StaticPage>
    );
};
