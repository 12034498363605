'use strict';

import React, { useContext } from 'react';
import useRouter from 'use-react-router/use-react-router';

import { OrderContext } from '../../order-reducer/context';
import { Actions } from '../../order-reducer/actions';
import { Modal, ModalCloseButton } from '../../components/modal';
import { AmountControl } from '../../components/amount-control';
import { imageSrcFromId } from '../../components/images';
import { menuItemBySlug } from './index';

export const MenuItemDetails = ({
    match: {
        params: { menuItem },
    },
}) => {
    const { state, dispatch } = useContext(OrderContext);
    const { history } = useRouter();
    const { products } = state;

    const itemDetails = menuItemBySlug(menuItem, products);

    if (!itemDetails) {
        return null;
    }

    const { id, name, description, price } = itemDetails;

    const imgSrc = imageSrcFromId(id);

    const amount = state.cart.items[id] || 0;

    const setCartItemAmount = amount => {
        dispatch({ type: Actions.setCartItemAmount, payload: { id, amount } });
    };

    const closeHandler = e => {
        e && e.preventDefault();

        history.push('/');
    };

    return (
        <Modal closeHandler={closeHandler}>
            <div className="menu-item-details">
                <ModalCloseButton closeHandler={closeHandler} />

                <div className="menu-item-details__content">
                    <div className="menu-item-details__image" style={{ backgroundImage: `url(${imgSrc})` }} />
                    <div className="menu-item-details__description">
                        <div className="menu-item-details__text-details content">
                            <h2>{name}</h2>

                            <p>{description}</p>
                        </div>

                        <div className="menu-item-details__actions">
                            <AmountControl amount={amount} price={price} setCartItemAmount={setCartItemAmount} name={name} closeHandler={closeHandler} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
