'use strict';

import React, { useReducer, useRef } from 'react';

import { Actions } from './actions';

import { FormInput, FormSelect } from '../../components/form-fields/form-input';
import { extract, NewsletterReducer } from './reducer';
import { initialState } from './state';
import { Icon } from '../../components/icon';
import { Link } from 'react-router-dom';
import { validateForm } from '../../domain/address';

import logo from '../../../assets/images/logo-vegan-food-delivery.svg';

export function isFormValid(form, config) {
    const errors = validateForm(form, config);
    return Object.keys(errors).length === 0;
}

export const NewsletterForm = ({ state, dispatch }) => {
    const formRef = useRef();

    const {
        form,
        config,
        validation: { errors },
    } = state;

    const validate = () => {
        dispatch({
            type: Actions.validateForm,
            payload: { form: formRef.current },
        });
    };

    const update = () => {
        dispatch({
            type: Actions.updateForm,
            payload: formRef.current,
        });

        validate();
    };

    const handleTosChange = e => {
        const value = e.currentTarget.checked;

        dispatch({ type: Actions.acceptTos, payload: value });

        validate();
    };

    const handleSubmit = e => {
        e.preventDefault();

        const formData = extract(new FormData(formRef.current));
        dispatch({ type: Actions.sendData, payload: formData });
    };

    const enableButton = isFormValid(form, config);

    return (
        <React.Fragment>
            <h2 className="has-text-centered">Subscribe to our awesome Newsletter ☝️</h2>

            <ul className="benefits-list">
                <li>🛵 Be the first to know when we deliver in your area! 🥳</li>
                <li>💵 Save money with exclusive Newsletter deals! 🙌</li>
            </ul>

            <form onSubmit={handleSubmit} ref={formRef}>
                <FormInput
                    name="name"
                    type="text"
                    required={config['name'].required.presence}
                    label="Name"
                    placeholder="Your full name"
                    errors={errors}
                    value={form['name']}
                    updateForm={update}
                    validateForm={validate}
                />

                <FormInput
                    name="email"
                    type="email"
                    required={config['email'].required.presence}
                    label="Email"
                    placeholder="Your best email address"
                    errors={errors}
                    value={form['email']}
                    updateForm={update}
                    validateForm={validate}
                />

                <FormInput
                    name="country"
                    type="text"
                    required={config['country'].required.presence}
                    label="Your country"
                    placeholder="The country you live in"
                    errors={errors}
                    value={form['country']}
                    updateForm={update}
                    validateForm={validate}
                />

                <div className="field has-margin-top">
                    <input
                        type="checkbox"
                        name="acceptTos"
                        id="acceptTos"
                        className="is-checkradio has-background-color"
                        checked={form['acceptTos']}
                        onChange={handleTosChange}
                    />
                    <label htmlFor="acceptTos">
                        I accept the <Link to="/legal#privacy">Privacy Policy</Link> and <Link to="/legal#tos">Terms of Service</Link> of delishios and wish to
                        receive the delishios newsletter. I explicitly consent to being contacted by delishios via Email. I understand that I can unsubscribe at
                        any time.
                        <span className="is-required">*</span>
                    </label>
                </div>

                <h2 className="has-text-centered">Optional</h2>
                <p className="has-text-centered">
                    The fields below help us understand where we should provide our delivery service and which kind if food we should put on our menu.
                </p>

                <FormInput
                    name="city"
                    type="text"
                    required={config['city'].required.presence}
                    label="City"
                    placeholder="The city you live in"
                    errors={errors}
                    value={form['city']}
                    updateForm={update}
                    validateForm={validate}
                />

                <FormInput
                    name="zip"
                    type="text"
                    required={config['zip'].required.presence}
                    label="Zip code / postal code"
                    placeholder="Your zip / postal code"
                    errors={errors}
                    value={form['zip']}
                    updateForm={update}
                    validateForm={validate}
                />

                <FormInput
                    name="favorites"
                    type="text"
                    required={config['favorites'].required.presence}
                    label="Your favorite foods"
                    placeholder="Tell us about your favorite foods"
                    errors={errors}
                    value={form['favorites']}
                    updateForm={update}
                    validateForm={validate}
                />

                <div className="has-text-centered next-step">
                    <button type="button" onClick={handleSubmit} className="button is-large is-primary" disabled={!enableButton}>
                        <Icon icon="fa fa-newspaper" />
                        <span>Subscribe</span>
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
};

export const NewsletterSent = () => {
    return (
        <React.Fragment>
            <h2 className="has-text-centered">Successfully subscribed! 🥳</h2>
            <p>Now please check your inbox (or spam folder) for our welcome message. 🧐</p>

            <div className="next-step">
                <Link to="/">Go to the delishios homepage</Link>
            </div>
        </React.Fragment>
    );
};

export const NewsletterPage = () => {
    const [state, dispatch] = useReducer(NewsletterReducer, initialState);

    const { state: currentState } = state;

    let Component = null;

    switch (currentState) {
        case 'edit':
            Component = <NewsletterForm state={state} dispatch={dispatch} />;
            break;
        case 'sent':
            Component = <NewsletterSent />;
            break;
    }

    return (
        <div className="content">
            <div className="logo">
                <img src={logo} alt="delishios logo" />
            </div>

            {Component}
        </div>
    );
};
