'use strict';

import React from 'react';

import classNames from 'classnames';

import { extractLocationId, hasSuggestions } from '.';
import { isValidSuggestion } from '../../../api/geo-coding/here-api';
import { Icon } from '../../icon';

export const Suggestion = ({ suggestion, active, setActive, handleSelect }) => {
    const {
        address: { city },
        countryCode,
        label,
        matchLevel,
        locationId,
    } = suggestion;

    const handleClick = e => {
        e.persist();
        e.preventDefault();

        handleSelect(e);
    };

    const handleMouseEnter = e => {
        const locationId = extractLocationId(e);

        setActive(locationId);
    };

    const handleMouseLeave = e => {
        const locationId = extractLocationId(e);

        if (active === locationId) {
            setActive(null);
        }
    };

    const isValid = isValidSuggestion(city, countryCode, matchLevel);

    const classes = classNames('geo-suggest__suggestion', {
        'is-active': locationId === active,
        'is-invalid': !isValid,
    });

    if (!isValid) {
        return <span className={classes}>{label}</span>;
    }

    return (
        <a
            href="#"
            className={classes}
            data-location={locationId}
            onClick={handleClick}
            onTouchStart={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <Icon icon="fa fa-check" />
            {label}
        </a>
    );
};

export const AddressSuggestions = ({ suggestions, active, handleSelect, setActive }) => {
    if (!hasSuggestions(suggestions)) {
        return null;
    }

    return (
        <div className="geo-suggest__suggestions">
            {suggestions.map(suggestion => (
                <Suggestion key={suggestion.locationId} suggestion={suggestion} active={active} setActive={setActive} handleSelect={handleSelect} />
            ))}
        </div>
    );
};
