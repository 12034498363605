import { EmailRegexp } from '../domain/validations/validations';

export const initialOrderState = {
    products: null,
    steps: {
        config: {
            menu: {
                validation: {
                    valid: false,
                },
            },
            address: {
                validation: {
                    valid: false,
                },
            },
            order: {
                validation: {
                    valid: false,
                },
            },
        },
        menuFilter: {
            currentFilter: null,
            config: {
                food: {
                    title: 'Food',
                },
                drinks: {
                    title: 'Drinks',
                },
                breakfast: {
                    title: 'Breakfast',
                },
                dessert: {
                    title: 'Dessert',
                },
            },
        },
        address: {
            geoCoded: null,
            form: {
                name: '',
                address: '',
                address2: '',
                city: '',
                zip: '',
                country: '',
                email: '',
                mobile: '',
                instructions: '',
            },
            config: {
                name: {
                    required: { presence: true },
                    length: { min: 4 },
                },
                address: {
                    required: { presence: true },
                    length: { min: 5 },
                },
                city: {
                    required: { presence: true },
                    length: { min: 4 },
                },
                zip: {
                    required: { presence: true },
                    length: { min: 4 },
                },
                email: {
                    required: { presence: true },
                    length: { min: 5 },
                    match: { pattern: EmailRegexp },
                },
                mobile: {
                    required: { presence: true },
                    length: { min: 6 },
                },
                acceptTos: {
                    required: { presence: true },
                    equality: { expectation: true },
                },
            },
            validation: {
                errors: {},
            },
        },
    },
    ui: {
        itemDetails: null,
        enableOrderButton: true,
    },
    cart: {
        items: {},
        dispatchOrder: false,
        subscribeNewsletter: false,
        acceptTos: false,
    },
    order: {},
};
