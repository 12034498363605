'use strict';

import React from 'react';

import { BulmaField } from './bulma-field';
import { BulmaInputControl, BulmaSelectControl, BulmaTextAreaControl } from './bulma-input-control';

export const FormTextArea = ({ name, required, label, placeholder, value = '', errors, wrapperClasses = '', inputClasses = '', updateForm, validateForm }) => {
    return (
        <BulmaField labelFor={name} labelText={label} required={required} className={wrapperClasses}>
            <BulmaTextAreaControl
                name={name}
                placeholder={placeholder}
                value={value}
                errors={errors[name]}
                className={inputClasses}
                updateForm={updateForm}
                validateForm={validateForm}
            />
        </BulmaField>
    );
};

export const FormInput = ({
    name,
    type,
    required,
    label,
    placeholder,
    value = '',
    errors,
    wrapperClasses = '',
    inputClasses = '',
    iconLeft,
    updateForm,
    validateForm,
}) => {
    return (
        <BulmaField labelFor={name} labelText={label} required={required} className={wrapperClasses}>
            <BulmaInputControl
                name={name}
                type={type}
                iconLeft={iconLeft}
                placeholder={placeholder}
                value={value}
                errors={errors[name]}
                className={inputClasses}
                updateForm={updateForm}
                validateForm={validateForm}
            />
        </BulmaField>
    );
};

export const FormSelect = ({ children, name, required, label, value = '', errors, wrapperClasses, updateForm, validateForm }) => {
    return (
        <BulmaField labelFor={name} labelText={label} required={required} className={wrapperClasses}>
            <BulmaSelectControl name={name} value={value} updateForm={updateForm} validateForm={validateForm}>
                {children}
            </BulmaSelectControl>
        </BulmaField>
    );
};
