'use strict';

import React, { useContext } from 'react';

import { OrderContext } from '../../order-reducer/context';
import { slugify } from '../../utils/string';
import { formatPrice } from '../../domain/price';

export const urlForItem = name => `#/${slugify(name)}`;

export const MenuItem = ({ data: { name, id, description, price, currency = 'EUR' }, data, imageSrc }) => {
    const { state } = useContext(OrderContext);

    const amount = state.cart.items[id] || 0;
    const url = urlForItem(name);
    const formattedPrice = formatPrice(price, currency);

    return (
        <a href={url} className="menu-item" style={{ backgroundImage: `url(${imageSrc})` }}>
            <div className="menu-item__price">
                <span>{formattedPrice}</span>
            </div>

            {amount > 0 && (
                <div className="menu-item__amount">
                    <span>{amount}</span>
                </div>
            )}

            <div className="menu-item__title">{name}</div>
            <div className="menu-item__content" />
        </a>
    );
};
