'use strict';

import React from 'react';
import useRouter from 'use-react-router';

import { mainContentClassForLocaction } from './helpers';

export const LandingPageLayout = ({ children }) => {
    const { location } = useRouter();

    return (
        <div className="landing-page">
            <section className={mainContentClassForLocaction(location)}>
                <div className="container">{children}</div>
            </section>
        </div>
    );
};
