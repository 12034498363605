export const defaultMessage = 'must match pattern';

export const defaultConfig = {
    message: defaultMessage,
    pattern: /\W/,
};

export function matchValidator(value, config = defaultConfig) {
    const errors = [];

    if (!config) {
        return errors;
    }

    const { message, pattern } = config;

    if (typeof pattern === 'undefined') {
        return errors;
    }

    const errorMessage = message || defaultMessage;

    if (!pattern.test(value)) {
        errors.push(errorMessage);
    }

    return errors;
}
