import get from 'lodash/get';

import { menuItemById } from '../pages/menu-selection';

export const GAID = 'UA-136382387-1';

export const Events = {
    selectAddressOnHomepage: {
        type: 'event',
        action: 'SELECT_ADDRESS_ON_HOMEPAGE',
        category: 'engagement',
    },
};

export function shouldTrack() {
    return true;
}

export function trackEvent({ action, category, label, value }, gtag = window.gtag) {
    if (shouldTrack() && gtag) {
        gtag('event', action, { event_category: category, event_label: label, value });
    }
}

export function trackItemsEvent(type, items, gtag = window.gtag) {
    if (shouldTrack() && gtag) {
        gtag('event', type, items);
    }
}

export function trackPageview(path, gtag = window.gtag) {
    if (shouldTrack() && gtag) {
        gtag('config', GAID, { page_path: path });
    }
}

export function trackNewsletterSubscription(gtag = window.gtag, fbq = window.fbq) {
    if (shouldTrack()) {
        trackEvent({ action: 'forms', category: 'submission', label: 'newsletter' }, gtag);
        fbq && fbq('track', 'Subscribe');
    }
}

export function trackAmountChange(state, payload, gtag = window.gtag) {
    const { id, amount } = payload;
    const { products } = state;

    if (!products) {
        return null;
    }

    const previousAmount = get(state, `cart.items[${id}]`, 0);
    const amountDiff = amount - previousAmount;

    const product = menuItemById(id, products);

    if (!product) {
        return null;
    }

    const { name, price } = product;

    // @TODO: add category here as well at some point:
    // https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_additions_to_and_removals_from_shopping_carts
    const trackedItems = [
        {
            id,
            name: name,
            quantity: amount,
            price: price / 100,
        },
    ];

    if (amountDiff > 0) {
        trackItemsEvent('add_to_cart', trackedItems, gtag);
    } else if (amountDiff < 0) {
        trackItemsEvent('remove_from_cart', trackedItems, gtag);
    }
}
