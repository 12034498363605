'use strict';

import React from 'react';
import { Icon } from './icon';

export const BrandClaims = () => (
    <div className="brand-claims">
        <a href="#/faq#vegan">
            <Icon icon="far fa-seedling" />
            <span>100% Vegan</span>
        </a>
        <a href="#/faq#fresh">
            <Icon icon="far fa-salad" />
            <span>Fresh Food</span>
        </a>
        <a href="#/faq#fast">
            <Icon icon="far fa-shipping-fast" />
            <span>Fast Delivery</span>
        </a>

        <a href="#/faq#payment">
            <Icon icon="far fa-money-bill-wave" />
            <span>Cash on delivery</span>
        </a>
    </div>
);
