import { requiredValidator } from './required-validator';
import { equalityValidator } from './equality-validator';
import { lengthValidator } from './length-validator';
import { matchValidator } from './match-validator';

export const EmailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const Validations = {
    required: requiredValidator,
    equals: equalityValidator,
    length: lengthValidator,
    match: matchValidator,
};

export function validate(value, config) {
    if (!config) {
        return {};
    }

    return Object.keys(Validations).reduce((errors, key) => {
        const validatorConfig = config[key];
        const validator = Validations[key];

        if (!validatorConfig || !validator) {
            return errors;
        }

        // execute the validator:
        const validatorErrors = validator(value, validatorConfig);
        const hasErrors = Array.isArray(validatorErrors) && validatorErrors.length > 0;

        if (hasErrors) {
            errors[key] = validatorErrors;
        }

        return errors;
    }, {});
}
