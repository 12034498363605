'use strict';

import React from 'react';

import { StaticPage } from './static-page';

export const AboutPage = () => (
    <StaticPage page="about">
        <div className="content">
            <h1>About us</h1>

            <p>
                <strong>Our mission:</strong> Provide access to affordable and
                delicious vegan food.
            </p>
        </div>
    </StaticPage>
);
