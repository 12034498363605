export const DEFAULT_VAT = 0.05;

export function getTotalOrderedItems(items) {
    if (!items) return 0;

    return Object.keys(items).reduce((total, key) => {
        total = total + items[key];
        return total;
    }, 0);
}

export function formatPrice(priceInCents, currency = 'EUR') {
    const price = priceInCents / 100;

    return Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: currency,
    }).format(price);
}

export function calculateTotalPrice(items, products) {
    if (!products) {
        return 0;
    }

    return Object.keys(items).reduce((sum, key) => {
        const amount = items[key];
        const id = parseInt(key);
        const product = products.find(item => item.id === id);
        const { price } = product;

        return sum + amount * price;
    }, 0);
}

export function calculateTotalVat(items, products) {
    if (!products) {
        return 0;
    }

    return Object.keys(items).reduce((sum, key) => {
        const amount = items[key];
        const id = parseInt(key);

        const product = products.find(item => item.id === id);
        const { vat = DEFAULT_VAT, price } = product;

        return sum + amount * price * vat;
    }, 0);
}

export function totalFormattedPrice(items, products) {
    return formatPrice(calculateTotalPrice(items, products));
}

export function totalFormattedVAT(items, products) {
    return formatPrice(calculateTotalVat(items, products));
}
