'use strict';

import React, { useContext } from 'react';

import { OrderContext } from '../../order-reducer/context';
import { getTotalOrderedItems, totalFormattedPrice } from '../../domain/price';
import { Link } from 'react-router-dom';
import { Icon } from '../../components/icon';

export const ShoppingCartLink = () => {
    const {
        state: {
            products,
            cart: { items },
        },
    } = useContext(OrderContext);

    const totalItems = getTotalOrderedItems(items);
    const totalPrice = totalFormattedPrice(items, products);
    const hasItems = totalItems > 0;

    return (
        <div className={`shopping-cart-link ${!hasItems && 'is-empty'}`}>
            <Link to="/cart">
                <Icon icon="far fa-shopping-cart" />
                <div className="cart-item-information">
                    <div className="total-items">{totalItems} items</div>
                    <div className="total-price">{totalPrice}</div>
                </div>
            </Link>
        </div>
    );
};
