'use strict';

import React from 'react';

export const LoadingIndicator = ({ children, isLoading }) => {
    return isLoading ? (
        <div className="loading-indicator">
            <span className="loader" />
            <div className="loading-text">Loading ...</div>
        </div>
    ) : (
        children
    );
};
