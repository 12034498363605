'use strict';

import React, { useContext } from 'react';

import { Actions } from '../../order-reducer/actions';
import { OrderContext } from '../../order-reducer/context';

import { imageSrcFromId } from '../../components/images';

import { formatPrice } from '../../domain/price';
import { DirectAmountControl } from '../../components/amount-control';
import { PrettyPrice } from '../../components/pretty-price';

export const LineItem = ({ item, amount }) => {
    const { dispatch } = useContext(OrderContext);

    const { id, currency, price, name } = item;

    const imageSrc = imageSrcFromId(id);

    const setCartItemAmount = amount => {
        dispatch({ type: Actions.setCartItemAmount, payload: { id, amount } });
    };

    const formattedItemPrice = formatPrice(price, currency);
    const totalFormattedItemPrice = formatPrice(price * amount, currency);

    return (
        <tr className="line-item">
            <td>
                <div className="line-item__image" style={{ backgroundImage: `url(${imageSrc})` }} />
                <div className="line-item__content">
                    <p className="title is-5">{name}</p>
                    <p className="subtitle is-5">{formattedItemPrice}</p>
                </div>

                <div className="line-item__actions">
                    <DirectAmountControl amount={amount} setCartItemAmount={setCartItemAmount} />

                    <div className="has-text-right">
                        <p>
                            Total:&nbsp;
                            <span className="has-text-weight-bold">
                                <PrettyPrice price={totalFormattedItemPrice} />
                            </span>
                        </p>
                    </div>
                </div>
            </td>
        </tr>
    );
};
