'use strict';

import React, { useContext } from 'react';
import useRouter from 'use-react-router';

import { OrderContext } from '../../order-reducer/context';

import { LoadingIndicator } from '../../components/loading-indicator';
import { isStepValid } from '../../domain/validate-order';
import { MenuItem } from './menu-item';
import { MenuItemDetails } from './menu-item-details';
import { imageSrcFromId } from '../../components/images';
import { InfoBox } from '../../components/infobox';
import { Icon } from '../../components/icon';
import { Route } from 'react-router';
import { slugify } from '../../utils/string';
import { BrandClaims } from '../../components/brand-claims';
import { AddressSelector } from '../../components/address-selector';

export function menuItemById(menuItemId, products) {
    return products.find(({ id }) => id === menuItemId);
}

export function menuItemBySlug(slug, products) {
    return products.find(({ name }) => slugify(name) === slug);
}

export const MenuNextStepSection = ({ hasItems }) => {
    const { history } = useRouter();

    const handleGotoCheckoutButtonClick = e => {
        e.preventDefault();

        history.push('/cart');
    };

    return (
        hasItems && (
            <div className="next-step">
                <div className="content">
                    <p>Excellent choice! 😋 If you're done, click the button below to continue.</p>
                </div>
                <button type="button" onClick={handleGotoCheckoutButtonClick} disabled={!hasItems} className="button is-large is-primary">
                    <Icon icon="fa fa-shopping-cart" />
                    <span>Go to cart</span>
                </button>
            </div>
        )
    );
};

export const MenuSelectionPage = () => {
    const { state } = useContext(OrderContext);

    const { products } = state;

    const hasProducts = Array.isArray(products) && products.length > 0;
    const hasItems = isStepValid(state, 'menu');

    return (
        <div className="menu-selection-step">
            <AddressSelector />

            <LoadingIndicator isLoading={!hasProducts}>
                <Route path="/:menuItem" component={MenuItemDetails} />
                {!hasItems && (
                    <InfoBox>
                        <p>Start adding delishios food to your shopping cart 😋</p>
                    </InfoBox>
                )}
                <div className="menu-selection">
                    {hasProducts && products.map(menuItem => <MenuItem data={menuItem} key={menuItem.id} imageSrc={imageSrcFromId(menuItem.id)} />)}
                </div>
                <MenuNextStepSection hasItems={hasItems} />
            </LoadingIndicator>

            <BrandClaims />
        </div>
    );
};
