/*eslint no-console: ["error", { allow: ["error", "info", "log"] }] */

import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';

const VERSION = '3';
const STORAGE_KEY = `state-${VERSION}`;

const BACKEND = window && window.sessionStorage;
const dynamic = ['products'];

export function persist(state) {
    const stateCopy = cloneDeep(state);

    dynamic.forEach(key => {
        unset(stateCopy, key);
    });

    if (BACKEND) {
        try {
            BACKEND.setItem(STORAGE_KEY, JSON.stringify(stateCopy));
        } catch (e) {
            console.error(e);
        }
    }

    return state;
}

export function load() {
    if (BACKEND) {
        try {
            const storedState = BACKEND.getItem(STORAGE_KEY);
            if (storedState) {
                return JSON.parse(storedState);
            }
        } catch (e) {
            console.error(e);
        }
    }
}
