'use strict';

import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import { StaticPage } from './static-page';
import { SocialMediaLink } from '../../components/social-media-link';

const faq = [
    {
        title: 'What is delishios?',
        id: 'what',
        description: <p>delishios is a vegan food delivery service. Our mission is to provide access to affordable and delicious vegan food.</p>,
    },
    { title: 'Who are you and why should I order from you?', id: 'who', description: <p>We are a small startup that tries to make a difference in a meat centric world.</p> },
    {
        title: 'What does \u201Cbeta\u201D mean?',
        id: 'beta',
        description: (
            <p>
                We labeled the site as {'\u201C'}beta{'\u201D'} because we are still working on many details and we are not yet fully operational.
                <br />
                Currently we are testing demand and our order platform to be able to provide you, our beloved customer, the best experience and service possible as soon as we
                launch.
            </p>
        ),
    },
    {
        title: 'Which payment methods do you offer',
        id: 'payment',
        description: <p>Since we are in beta right now, we offer cash only on delivery. We have plans to have credit card payment available soonish.</p>,
    },
    {
        title: 'Do you prepare the food yourself?',
        id: 'preparation',
        description: <p>Yes. We prepare the delishios food ourselves. This way we can guarantee the best quality food for you.</p>,
    },
    {
        title: 'Do you delivery to my city / area?',
        id: 'delivery-area',
        description: (
            <p>
                Right now we are planning the first location. Our initial test delivery run depends on interest and demand. Other locations will be serviced once we have all the kinks worked out.
                <br />
                If you want us to get to your area faster, make sure to subscribe to our newsletter and let us know your city and post code. 😊
            </p>
        ),
    },
    {
        title: 'How much do you charge for delivery?',
        id: 'delivery-cost',
        description: <p>Delivery is free of charge for now. We might have a minimum order value as soon as we start operations for the general public.</p>,
    },
    { title: '100% Vegan Menu', id: 'vegan', description: <p>No animals are harmed for your food. All items on our menu are 100% vegan.</p> },
    { title: 'Fresh Ingredients', id: 'fresh', description: <p>We use high quality fresh or frozen products where feasible.</p> },
    {
        title: 'Fast Delivery',
        id: 'fast',
        description: <p>Delivery times will vary of course, but we target a delivery time of 30 minutes max.</p>,
    },
];

export const TOC = ({ items, baseLocation }) => {
    return (
        <ol className="table-of-contents">
            {items.map(({ title, id }) => {
                return (
                    <li key={id}>
                        <Link to={`${baseLocation}#${id}`}>{title}</Link>
                    </li>
                );
            })}
        </ol>
    );
};

export const FAQ = ({ items }) => {
    return (
        <div className="faq-content">
            {items.map(({ title, id, description }) => {
                return (
                    <div id={id} key={id} className="faq-section">
                        <h2 className="faq-section__title">{title}</h2>
                        <div className="faq-section__content">{description}</div>
                    </div>
                );
            })}
        </div>
    );
};

export const FaqPage = () => (
    <StaticPage page="faq">
        <div className="content">
            <h1>You have questions? We've got answers!</h1>
            <p>Thank you for your interest in our service. We compiled a few answers to frequently asked questions about delishios.</p>
            <p>
                If you don't find the answers you are looking for, make sure to send us a quick email to:&nbsp;
                <SocialMediaLink url="mailto:ms@delishios.com" icon="fas fa-envelope" text="ms@delishios.com" />
            </p>

            <TOC items={faq} baseLocation="/faq" />
            <FAQ items={faq} />
        </div>
    </StaticPage>
);
