'use strict';

import React, { useContext, createContext } from 'react';

import { OrderContext } from '../../order-reducer/context';
import { Actions } from '../../order-reducer/actions';
import { FormInput, FormTextArea } from '../../components/form-fields/form-input';
import { AddressSelector } from '../../components/address-selector';

export const AddressForm = ({ parentForm }) => {
    const { state, dispatch } = useContext(OrderContext);

    const {
        form,
        validation: { errors },
    } = state.steps.address;

    const updateForm = e => {
        dispatch({
            type: Actions.updateAddress,
            payload: parentForm.current,
        });

        validateForm();
    };

    const validateForm = () => {
        dispatch({
            type: Actions.validateAddressForm,
            payload: { form: parentForm.current },
        });
    };

    const { name, address2, email, mobile, instructions } = form;

    return (
        <div className="content">
            <h2 className="has-text-centered">Contact</h2>
            <p className="has-text-centered">In case we need to contact you about your order.</p>

            <div className="form-wrapper">
                <div className="contact-information">
                    <FormInput
                        name="name"
                        value={name}
                        required={true}
                        label="Name"
                        errors={errors}
                        iconLeft="user"
                        placeholder="your name"
                        updateForm={updateForm}
                        validateForm={validateForm}
                    />

                    <FormInput
                        name="email"
                        type="email"
                        value={email}
                        required={true}
                        label="Email"
                        errors={errors}
                        iconLeft="at"
                        placeholder="your e-mail"
                        updateForm={updateForm}
                        validateForm={validateForm}
                    />

                    <FormInput
                        name="mobile"
                        type="tel"
                        value={mobile}
                        required={true}
                        label="Mobile number"
                        errors={errors}
                        iconLeft="mobile-alt"
                        placeholder="your mobile phone number"
                        updateForm={updateForm}
                        validateForm={validateForm}
                    />
                </div>
            </div>

            <h2 className="has-text-centered">Address</h2>
            <p className="has-text-centered">So we can find you and deliver your delishios food.</p>

            <div className="form-wrapper">
                <div className="selected-address">
                    <span className="selected-address__label">Address</span>
                    <span className="selected-address__address">
                        <AddressSelector />
                    </span>
                </div>

                <div className="delivery-information">
                    <div className="delivery-information__inputs">
                        <FormInput
                            name="address2"
                            value={address2}
                            label="Building, Apartment etc"
                            required={false}
                            errors={errors}
                            iconLeft="map"
                            placeholder="apartment, building etc"
                            updateForm={updateForm}
                            validateForm={validateForm}
                        />

                        <FormTextArea
                            name="instructions"
                            value={instructions}
                            label="Delivery instructions"
                            required={false}
                            errors={errors}
                            placeholder="Delivery instructions"
                            updateForm={updateForm}
                            validateForm={validateForm}
                        />
                    </div>
                </div>
            </div>

            <input type="hidden" name="country" value="Cyprus" />
        </div>
    );
};
