'use strict';

import React, { useEffect } from 'react';
import useRouter from 'use-react-router';

export function getElementForHash(hash) {
    const anchorId = hash.includes('#') ? hash.split('#').pop() : hash;

    return document.getElementById(anchorId);
}

// scrolls to top when router location changes
export const ScrollControl = ({ children }) => {
    const { location } = useRouter();
    const { pathname, hash } = location;

    const hasFragment = Boolean(hash);

    useEffect(() => {
        if (hasFragment) {
            const element = getElementForHash(hash);

            if (element) {
                setTimeout(() => {
                    element.scrollIntoView(true);
                }, 300);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [pathname, hash]);

    return children;
};
