'use strict';

import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => (
    <footer className="footer">
        <div className="container">
            <nav className="level">
                <p className="level-item has-text-centered">
                    <Link to="/about">About us</Link>
                </p>

                <p className="level-item has-text-centered">
                    <Link to="/faq">FAQ</Link>
                </p>

                <p className="level-item has-text-centered">
                    <Link to="/contact">Contact</Link>
                </p>

                <p className="level-item has-text-centered">
                    <Link to="/legal">Legal</Link>
                </p>

                <p className="level-item has-text-centered">
                    <Link to="/newsletter">Newsletter</Link>
                </p>
            </nav>
        </div>
    </footer>
);
