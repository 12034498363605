'use strict';

import React from 'react';

export const SocialMediaLink = ({ url, text, icon }) => {
    return (
        <a href={url} target="_blank" rel="noopener" className="is-medium external-profile-link">
            <span className="icon is-medium">
                <i className={icon} />
            </span>
            {text && <span>{text}</span>}
        </a>
    );
};
