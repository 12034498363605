// make this more dynamic when adding other countries and cities:
import get from 'lodash/get';
import merge from 'lodash/merge';

import { validate } from './validations/validations';

export function isInDeliveryArea(city, country) {
    return country === 'CYP';
}

export function inDeliveryAreaFromState(state) {
    const geoCoded = get(state, 'steps.address.geoCoded');

    if (!geoCoded) {
        return false;
    }

    const { city, country } = geoCoded;

    return isInDeliveryArea(city, country);
}

export function extractOrderAddress(formData) {
    return ['name', 'address2', 'instructions', 'email', 'mobile'].reduce((result, key) => {
        result[key] = formData.get(key);
        return result;
    }, {});
}

export function validateForm(form, config) {
    return Object.keys(form).reduce((errors, key) => {
        const value = form[key];
        const validationConfig = config[key];

        const validationErrors = validate(value, validationConfig);
        if (Object.keys(validationErrors).length > 0) {
            if (!errors[key]) {
                errors[key] = {};
            }
            errors[key] = merge(errors[key], validationErrors);
        }

        return errors;
    }, {});
}
