/*eslint no-console: ["error", { allow: ["error", "info", "log"] }] */

import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import merge from 'lodash/merge';
import axios from 'axios';

import { Actions } from './actions';
import { validateForm } from '../../domain/address';

import { trackNewsletterSubscription } from '../../tracking/events';

export function extract(formData) {
    const data = {};

    for (let pair of formData.entries()) {
        data[pair[0]] = pair[1];
    }

    // normalize checkbox
    const { acceptTos } = data;
    if (acceptTos && acceptTos === 'on') {
        data['acceptTos'] = true;
    }

    return data;
}

export function update(state, payload) {
    const newForm = extract(new FormData(payload));

    const form = merge(state.form, newForm);

    return {
        ...state,
        form,
    };
}

export function acceptTos(state, payload) {
    set(state, 'form.acceptTos', payload);

    return state;
}

export function sendData(state, payload) {
    axios
        .post('/.netlify/functions/subscribe-user', payload)
        .then(result => console.log(result))
        .catch(err => console.error(err));

    trackNewsletterSubscription();

    state.state = 'sent';

    return state;
}

export function validate(state) {
    const { form, config } = state;

    const errors = validateForm(form, config);

    set(state, 'validation.errors', errors);

    return state;
}

export const NewsletterReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case Actions.updateForm:
            return update(cloneDeep(state), payload);
        case Actions.validateForm:
            return validate(cloneDeep(state));
        case Actions.acceptTos:
            return acceptTos(cloneDeep(state), payload);
        case Actions.sendData:
            return sendData(cloneDeep(state), payload);
        default:
            return state;
    }
};
