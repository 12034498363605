'use strict';

import React, { useContext, useRef } from 'react';

import useRouter from 'use-react-router/use-react-router';

import { Actions } from '../../order-reducer/actions';
import { OrderContext } from '../../order-reducer/context';

import { AddressForm } from './address-form';
import { ConfirmationForm } from './confirmation-form';

import { Icon } from '../../components/icon';
import { isOrderValid } from '../../domain/validate-order';

import { OrderSummary } from './order';
import { BrandClaims } from '../../components/brand-claims';

export const ShoppingCartPage = () => {
    const { history } = useRouter();
    const { state, dispatch } = useContext(OrderContext);

    const formRef = useRef();

    const {
        products,
        cart,
        cart: { dispatchOrder },
        ui: { enableOrderButton },
    } = state;

    const handleOrderButtonClick = e => {
        e.preventDefault();

        if (!dispatchOrder) {
            dispatch({ type: Actions.setDispatchOrder });
            history.push('/delivery');
        }
    };

    const handleFormSubmit = e => {
        e.preventDefault();
    };

    const { items } = cart;

    const orderIsValid = isOrderValid(state);

    return (
        <div className="order-confirmation-step">
            <div className="content">
                <OrderSummary items={items} products={products} />

                <form action="#" onSubmit={handleFormSubmit} ref={formRef}>
                    <AddressForm parentForm={formRef} />
                    <ConfirmationForm />

                    <div className="has-text-centered next-step">
                        <p>All done? Let's eat!</p>

                        <button
                            type="button"
                            onClick={handleOrderButtonClick}
                            className="button is-large is-primary"
                            disabled={!enableOrderButton || !orderIsValid}>
                            <Icon icon="fa fa-parachute-box" />
                            <span>Order now</span>
                        </button>
                    </div>

                    <BrandClaims />
                </form>
            </div>
        </div>
    );
};
