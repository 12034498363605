'use strict';

import React from 'react';

import { StaticPage } from './static-page';

export const LegalPage = () => {
    return (
        <StaticPage page="legal">
            <div className="content">
                <h1>Legal stuff</h1>

                <h2>Imprint</h2>
                <p>delishios is a project of Next Level International Software Ltd.</p>
                <h3>Address:</h3>
                <p>
                    Agias Sofias 12, Apt 302
                    <br />
                    6052 Larnaca, Cyprus
                </p>

                <h2 id="privacy">Privacy Policy</h2>

                <p>
                    Your privacy is important to us. It is Next Level International Software Ltd's policy to respect your privacy regarding any information we
                    may collect from you across our website, <a href="https://delishios.com">https://delishios.com</a>, and other sites we own and operate.
                </p>
                <h3>1. Information we collect</h3>
                <h4>Log data</h4>
                <p>
                    When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your computer’s
                    Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each
                    page, and other details.
                </p>
                <h4>Device data</h4>
                <p>
                    We may also collect data about the device you’re using to access our website. This data may include the device type, operating system,
                    unique device identifiers, device settings, and geo-location data. What we collect can depend on the individual settings of your device and
                    software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available
                    to us.
                </p>
                <h4>Personal information</h4>
                <p>We may ask for personal information, such as your:</p>
                <ul>
                    <li>Name</li>
                    <li>Email</li>
                    <li>Social media profiles</li>
                    <li>Date of birth</li>
                    <li>Phone/mobile number</li>
                    <li>Home/Mailing address</li>
                    <li>Work address</li>
                    <li>Payment information</li>
                </ul>
                <h3>2. Legal bases for processing</h3>
                <p>
                    We will process your personal information lawfully, fairly and in a transparent manner. We collect and process information about you only
                    where we have legal bases for doing so.
                </p>
                <p>These legal bases depend on the services you use and how you use them, meaning we collect and use your information only where:</p>
                <ul>
                    <li>
                        it’s necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a
                        contract (for example, when we provide a service you request from us);
                    </li>
                    <li>
                        it satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to
                        market and promote our services, and to protect our legal rights and interests;
                    </li>
                    <li>you give us consent to do so for a specific purpose (for example, you might consent to us sending you our newsletter); or</li>
                    <li>we need to process your data to comply with a legal obligation.</li>
                </ul>
                <p>
                    Where you consent to our use of information about you for a specific purpose, you have the right to change your mind at any time (but this
                    will not affect any processing that has already taken place).
                </p>
                <p>
                    We don’t keep personal information for longer than is necessary. While we retain this information, we will protect it within commercially
                    acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification. That said, we advise
                    that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security. If necessary, we may retain
                    your personal information for our compliance with a legal obligation or in order to protect your vital interests or the vital interests of
                    another natural person.
                </p>
                <h3>3. Collection and use of information</h3>
                <p>
                    We may collect, hold, use and disclose information for the following purposes and personal information will not be further processed in a
                    manner that is incompatible with these purposes:
                </p>
                <ul>
                    <li>to enable you to customise or personalise your experience of our website;</li>
                    <li>to attribute any content (eg. posts and comments) you submit that we publish on our website;</li>
                    <li>to process any transactional or ongoing payments;</li>
                    <li>to deliver products and/or services to you;</li>
                    <li>to enable you to access and use our website, associated applications and associated social media platforms;</li>
                    <li>to contact and communicate with you;</li>
                    <li>for internal record keeping and administrative purposes;</li>
                    <li>
                        for analytics, market research and business development, including to operate and improve our website, associated applications and
                        associated social media platforms;
                    </li>
                    <li>to run competitions and/or offer additional benefits to you;</li>
                    <li>
                        for advertising and marketing, including to send you promotional information about our products and services and information about third
                        parties that we consider may be of interest to you;
                    </li>
                    <li>to comply with our legal obligations and resolve any disputes that we may have; and</li>
                    <li>to consider your employment application.</li>
                </ul>
                <h3>4. Disclosure of personal information to third parties</h3>
                <p>We may disclose personal information to:</p>
                <ul>
                    <li>
                        third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service
                        providers, data storage, hosting and server providers, ad networks, analytics, error loggers, debt collectors, maintenance or
                        problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;
                    </li>
                    <li>our employees, contractors and/or related entities;</li>
                    <li>sponsors or promoters of any competition we run;</li>
                    <li>
                        credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have
                        provided to you;
                    </li>
                    <li>
                        courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective
                        legal proceedings, or in order to establish, exercise or defend our legal rights;
                    </li>
                    <li>
                        third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to
                        you; and
                    </li>
                    <li>third parties to collect and process data.</li>
                </ul>
                <h3>5. International transfers of personal information</h3>
                <p>
                    The personal information we collect is stored and processed in United States, United Kingdom, Germany, Cyprus and Netherlands, or where we
                    or our partners, affiliates and third-party providers maintain facilities. By providing us with your personal information, you consent to
                    the disclosure to these overseas third parties.
                </p>
                <p>
                    We will ensure that any transfer of personal information from countries in the European Economic Area (EEA) to countries outside the EEA
                    will be protected by appropriate safeguards, for example by using standard data protection clauses approved by the European Commission, or
                    the use of binding corporate rules or other legally accepted means.
                </p>
                <p>
                    Where we transfer personal information from a non-EEA country to another country, you acknowledge that third parties in other jurisdictions
                    may not be subject to similar data protection laws to the ones in our jurisdiction. There are risks if any such third party engages in any
                    act or practice that would contravene the data privacy laws in our jurisdiction and this might mean that you will not be able to seek
                    redress under our jurisdiction’s privacy laws.
                </p>
                <h3>6. Your rights and controlling your personal information</h3>
                <p>
                    <strong>Choice and consent:</strong> By providing personal information to us, you consent to us collecting, holding, using and disclosing
                    your personal information in accordance with this privacy policy. If you are under 16 years of age, you must have, and warrant to the extent
                    permitted by law to us, that you have your parent or legal guardian’s permission to access and use the website and they (your parents or
                    guardian) have consented to you providing us with your personal information. You do not have to provide personal information to us, however,
                    if you do not, it may affect your use of this website or the products and/or services offered on or through it.
                </p>
                <p>
                    <strong>Information from third parties:</strong> If we receive personal information about you from a third party, we will protect it as set
                    out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you
                    have such person’s consent to provide the personal information to us.
                </p>
                <p>
                    <strong>Restrict:</strong> You may choose to restrict the collection or use of your personal information. If you have previously agreed to
                    us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details
                    below. If you ask us to restrict or limit how we process your personal information, we will let you know how the restriction affects your
                    use of our website or products and services.
                </p>
                <p>
                    <strong>Access and data portability:</strong> You may request details of the personal information that we hold about you. You may request a
                    copy of the personal information we hold about you. Where possible, we will provide this information in CSV format or other easily readable
                    machine format. You may request that we erase the personal information we hold about you at any time. You may also request that we transfer
                    this personal information to another third party.
                </p>
                <p>
                    <strong>Correction:</strong> If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or
                    misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate,
                    incomplete, misleading or out of date.
                </p>
                <p>
                    <strong>Notification of data breaches:</strong> We will comply laws applicable to us in respect of any data breach.
                </p>
                <p>
                    <strong>Complaints:</strong> If you believe that we have breached a relevant data protection law and wish to make a complaint, please
                    contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and
                    respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also
                    have the right to contact a regulatory body or data protection authority in relation to your complaint.
                </p>
                <p>
                    <strong>Unsubscribe:</strong> To unsubscribe from our e-mail database or opt-out of communications (including marketing communications),
                    please contact us using the details below or opt-out using the opt-out facilities provided in the communication.
                </p>
                <h3>7. Cookies</h3>
                <p>
                    We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website
                    stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based
                    on preferences you have specified. Please refer to our Cookie Policy for more information.
                </p>
                <h3>8. Business transfers</h3>
                <p>
                    If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data among the
                    assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may
                    continue to use your personal information according to this policy.
                </p>
                <h3>9. Limits of our policy</h3>
                <p>
                    Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of
                    those sites, and cannot accept responsibility or liability for their respective privacy practices.
                </p>
                <h3>10. Changes to this policy</h3>
                <p>
                    At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know
                    about changes via our website. Your continued use of this site after any changes to this policy will be regarded as acceptance of our
                    practices around privacy and personal information.{' '}
                </p>
                <p>
                    If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your personal information,
                    we will ask you to re-consent to the amended privacy policy.
                </p>
                <p>
                    <strong>Next Level International Software Ltd Data Controller</strong>
                    <br />
                    Marcel Scherf
                    <br />
                    ms@delishios.com
                </p>
                <p>
                    <strong>Next Level International Software Ltd Data Protection Officer</strong>
                    <br />
                    Marcel Scherf
                    <br />
                    ms@delishios.com
                </p>
                <p>This policy is effective as of 1 January 2019.</p>

                <h2 id="tos">Next Level International Software Ltd Terms of Service</h2>

                <h3>1. Terms</h3>
                <p>
                    By accessing the website at <a href="https://delishios.com">https://delishios.com</a>, you are agreeing to be bound by these terms of
                    service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do
                    not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are
                    protected by applicable copyright and trademark law.
                </p>
                <h3>2. Use License</h3>
                <ol type="a">
                    <li>
                        Permission is granted to temporarily download one copy of the materials (information or software) on Next Level International Software
                        Ltd's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under
                        this license you may not:
                        <ol type="i">
                            <li>modify or copy the materials;</li>
                            <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                            <li>attempt to decompile or reverse engineer any software contained on Next Level International Software Ltd's website;</li>
                            <li>remove any copyright or other proprietary notations from the materials; or</li>
                            <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                        </ol>
                    </li>
                    <li>
                        This license shall automatically terminate if you violate any of these restrictions and may be terminated by Next Level International
                        Software Ltd at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any
                        downloaded materials in your possession whether in electronic or printed format.
                    </li>
                </ol>
                <h3>3. Disclaimer</h3>
                <ol type="a">
                    <li>
                        The materials on Next Level International Software Ltd's website are provided on an 'as is' basis. Next Level International Software Ltd
                        makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied
                        warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other
                        violation of rights.
                    </li>
                    <li>
                        Further, Next Level International Software Ltd does not warrant or make any representations concerning the accuracy, likely results, or
                        reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
                    </li>
                </ol>
                <h3>4. Limitations</h3>
                <p>
                    In no event shall Next Level International Software Ltd or its suppliers be liable for any damages (including, without limitation, damages
                    for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Next Level
                    International Software Ltd's website, even if Next Level International Software Ltd or a Next Level International Software Ltd authorized
                    representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations
                    on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
                </p>
                <h3>5. Accuracy of materials</h3>
                <p>
                    The materials appearing on Next Level International Software Ltd's website could include technical, typographical, or photographic errors.
                    Next Level International Software Ltd does not warrant that any of the materials on its website are accurate, complete or current. Next
                    Level International Software Ltd may make changes to the materials contained on its website at any time without notice. However Next Level
                    International Software Ltd does not make any commitment to update the materials.
                </p>
                <h3>6. Links</h3>
                <p>
                    Next Level International Software Ltd has not reviewed all of the sites linked to its website and is not responsible for the contents of any
                    such linked site. The inclusion of any link does not imply endorsement by Next Level International Software Ltd of the site. Use of any such
                    linked website is at the user's own risk.
                </p>
                <h3>7. Modifications</h3>
                <p>
                    Next Level International Software Ltd may revise these terms of service for its website at any time without notice. By using this website
                    you are agreeing to be bound by the then current version of these terms of service.
                </p>
                <h3>8. Governing Law</h3>
                <p>
                    These terms and conditions are governed by and construed in accordance with the laws of Cyprus and you irrevocably submit to the exclusive
                    jurisdiction of the courts in that State or location.
                </p>

                <h2 id="cookies">Cookie Policy</h2>

                <p>
                    We use cookies to help improve your experience of <a href="https://delishios.com">https://delishios.com</a>. This cookie policy is part of
                    Next Level International Software Ltd's privacy policy, and covers the use of cookies between your device and our site. We also provide
                    basic information on third-party services we may use, who may also use cookies as part of their service, though they are not covered by our
                    policy.
                </p>
                <p>
                    If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from{' '}
                    <a href="https://delishios.com">https://delishios.com</a>, with the understanding that we may be unable to provide you with some of your
                    desired content and services.
                </p>
                <h3>What is a cookie?</h3>
                <p>
                    A cookie is a small piece of data that a website stores on your device when you visit, typically containing information about the website
                    itself, a unique identifier that allows the site to recognise your web browser when you return, additional data that serves the purpose of
                    the cookie, and the lifespan of the cookie itself.
                </p>
                <p>
                    Cookies are used to enable certain features (eg. logging in), to track site usage (eg. analytics), to store your user settings (eg.
                    timezone, notification preferences), and to personalise your content (eg. advertising, language).
                </p>
                <p>
                    Cookies set by the website you are visiting are normally referred to as “first-party cookies”, and typically only track your activity on
                    that particular site. Cookies set by other sites and companies (ie. third parties) are called “third-party cookies”, and can be used to
                    track you on other websites that use the same third-party service.
                </p>
                <h3>Types of cookies and how we use them</h3>
                <h4>Essential cookies</h4>
                <p>
                    Essential cookies are crucial to your experience of a website, enabling core features like user logins, account management, shopping carts
                    and payment processing. We use essential cookies to enable certain functions on our website.
                </p>
                <h4>Performance cookies</h4>
                <p>
                    Performance cookies are used in the tracking of how you use a website during your visit, without collecting personal information about you.
                    Typically, this information is anonymous and aggregated with information tracked across all site users, to help companies understand visitor
                    usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their
                    audience’s overall website experience. These cookies may be set by the website you’re visiting (first-party) or by third-party services. We
                    use performance cookies on our site.
                </p>
                <h4>Functionality cookies</h4>
                <p>
                    Functionality cookies are used in collecting information about your device and any settings you may configure on the website you’re visiting
                    (like language and timezone settings). With this information, websites can provide you with customised, enhanced or optimised content and
                    services. These cookies may be set by the website you’re visiting (first-party) or by third-party service. We use functionality cookies for
                    selected features on our site.
                </p>
                <h4>Targeting/advertising cookies</h4>
                <p>
                    Targeting/advertising cookies are used in determining what promotional content is more relevant and appropriate to you and your interests.
                    Websites may use them to deliver targeted advertising or to limit the number of times you see an advertisement. This helps companies improve
                    the effectiveness of their campaigns and the quality of content presented to you. These cookies may be set by the website you’re visiting
                    (first-party) or by third-party services. Targeting/advertising cookies set by third-parties may be used to track you on other websites that
                    use the same third-party service. We use targeting/advertising cookies on our site.
                </p>
                <h3>Third-party cookies on our site</h3>
                <p>
                    We may employ third-party companies and individuals on our websites—for example, analytics providers and content partners. We grant these
                    third parties access to selected information to perform specific tasks on our behalf. They may also set third-party cookies in order to
                    deliver the services they are providing. Third-party cookies can be used to track you on other websites that use the same third-party
                    service. As we have no control over third-party cookies, they are not covered by Next Level International Software Ltd's cookie policy.
                </p>
                <h4>Our third-party privacy promise</h4>
                <p>
                    We review the privacy policies of all our third-party providers before enlisting their services to ensure their practices align with ours.
                    We will never knowingly include third-party services that compromise or violate the privacy of our users.
                </p>
                <h3>How you can control or opt out of cookies</h3>
                <p>
                    If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our website. Most browsers are configured
                    to accept cookies by default, but you can update these settings to either refuse cookies altogether, or to notify you when a website is
                    trying to set or update a cookie.
                </p>
                <p>If you browse websites from multiple devices, you may need to update your settings on each individual device.</p>
                <p>
                    Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies may mean you are unable to
                    access certain features and content across the sites you visit.
                </p>
            </div>
        </StaticPage>
    );
};
