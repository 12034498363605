/*eslint no-console: ["error", { allow: ["error", "info", "log"] }] */

import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import axios from 'axios';

import { Actions } from './actions';

import { extractOrderAddress, validateForm } from '../domain/address';
import { validateOrder } from '../domain/validate-order';
import { persist } from '../persistence';

import { Events, trackAmountChange, trackEvent, trackPageview } from '../tracking/events';

export function updateAddress(state, payload) {
    const newForm = extractOrderAddress(new FormData(payload));

    const currentForm = get(state, 'steps.address.form');
    const form = { ...cloneDeep(currentForm), ...cloneDeep(newForm) };

    set(state, 'steps.address.form', form);

    return state;
}

export function putAddress(state, payload) {
    const currentForm = get(state, 'steps.address.form');
    const form = { ...cloneDeep(currentForm), ...cloneDeep(payload) };

    set(state, 'steps.address.form', form);
    set(state, 'steps.address.geoCoded', payload);

    return state;
}

// async after hook:
export function afterPutAddress(state) {
    const address = get(state, 'steps.address.geoCoded.label');

    trackEvent({
        ...cloneDeep(Events.selectAddressOnHomepage),
        label: address,
    });
}

export function resetAddress(state) {
    set(state, 'steps.address.geoCoded', null);
    set(state, 'steps.address.form.address', null);
    set(state, 'steps.address.form.city', null);
    set(state, 'steps.address.form.zip', null);

    return state;
}

export function validateAddress(state) {
    const { form, config } = state.steps.address;

    const errors = validateForm(form, config);

    set(state, 'steps.address.validation.errors', errors);

    return state;
}

export function setCartItemAmount(state, payload) {
    const previousState = cloneDeep(state);
    const { id, amount } = payload;

    const newCartItems = cloneDeep(state.cart.items);

    if (amount <= 0) {
        delete newCartItems[id];
    } else {
        newCartItems[id] = amount;
    }

    set(state, 'cart.items', newCartItems);

    trackAmountChange(previousState, payload, window.gtag);

    return state;
}

export function setMenuFilter(state, payload) {
    set(state, 'steps.menuFilter.currentFilter', payload);

    return state;
}

export function setAcceptTos(state, payload) {
    set(state, 'cart.acceptTos', payload);

    return state;
}

export function setSubscribeNewsletter(state, payload) {
    set(state, 'cart.subscribeNewsletter', payload);

    return state;
}

export function setDispatchOrder(state) {
    set(state, 'cart.dispatchOrder', true);

    return state;
}

export function extractDataFromState(state) {
    const cartItems = get(state, 'cart.items');
    const address = get(state, 'steps.address.form');
    const flags = {
        subscribeNewsletter: get(state, 'cart.subscribeNewsletter'),
        acceptTos: get(state, 'cart.acceptTos'),
    };

    return { cartItems, address, flags };
}

export function sendOrder(state) {
    axios
        .post('/.netlify/functions/create-order', extractDataFromState(state))
        .then(result => console.log(result))
        .catch(err => console.error(err));

    return state;
}

export function resetOrder(state) {
    set(state, 'cart.items', {});
    set(state, 'cart.subscribeNewsletter', false);
    set(state, 'cart.acceptTos', false);
    set(state, 'cart.dispatchOrder', false);
    set(state, 'steps.config.menu.validation.valid', false);
    set(state, 'steps.config.order.validation.valid', false);
    set(state, 'ui.enableOrderButton', true);
    set(state, 'order', {});

    return state;
}

export function disableOrderButton(state) {
    set(state, 'ui.enableOrderButton', false);

    return state;
}

export function fetchProducts(state, payload) {
    const { dispatch } = payload;

    axios
        .get('/.netlify/functions/products')
        .then(response => {
            const { data } = response;
            console.log(response);
            dispatch({ type: Actions.putProducts, payload: data });
        })
        .catch(err => console.error(err));

    return state;
}

export function putProducts(state, payload) {
    return {
        ...cloneDeep(state),
        products: payload,
    };
}

export function locationChanged(state, payload) {
    const {
        location: { pathname },
    } = payload;

    trackPageview(pathname);

    return state;
}

// execute actions after another action:
const afterActionHooks = {
    [Actions.putAddress]: [validateAddress, validateOrder],
    [Actions.resetAddress]: [validateAddress, validateOrder],
    [Actions.validateAddressForm]: [validateOrder],
    [Actions.setCartItemAmount]: [validateOrder],
    [Actions.setSubscribeNewsletter]: [validateOrder],
    [Actions.setAcceptTos]: [validateOrder],
    [Actions.setDispatchOrder]: [disableOrderButton, validateOrder, sendOrder, resetOrder],
};

// add tracking for fb pixel and google analytics as after action hooks:
const asyncAfterActionHooks = {
    [Actions.putAddress]: [afterPutAddress],
};

const ReducerActions = {
    [Actions.updateAddress]: updateAddress,
    [Actions.putAddress]: putAddress,
    [Actions.resetAddress]: resetAddress,
    [Actions.validateAddressForm]: validateAddress,
    [Actions.validateOrder]: validateOrder,
    [Actions.setCartItemAmount]: setCartItemAmount,
    [Actions.setMenuFilter]: setMenuFilter,
    [Actions.setAcceptTos]: setAcceptTos,
    [Actions.setSubscribeNewsletter]: setSubscribeNewsletter,
    [Actions.setDispatchOrder]: setDispatchOrder,
    [Actions.fetchProducts]: fetchProducts,
    [Actions.putProducts]: putProducts,
    [Actions.locationChanged]: locationChanged,
};

export function runAfterActionHooks(hooks, state) {
    if (!hooks) {
        return persist(state);
    }

    console.info('executing after action hooks');
    return hooks.reduce((hookState, hook) => {
        return hook(cloneDeep(hookState));
    }, cloneDeep(state));
}

export async function runAsyncAfterActionHooks(hooks, state) {
    if (!hooks) {
        return null;
    }

    console.info('executing after async action hooks');
    hooks.forEach(hook => {
        return hook(cloneDeep(state));
    }, state);
}

export const OrderReducer = (state, action) => {
    const { type, payload } = action;

    const reducerAction = ReducerActions[type];

    if (!reducerAction) {
        console.info('Action fell through: ', action);
        return state;
    }

    console.info('executing Action: ', type, payload);
    const newState = reducerAction(cloneDeep(state), payload);

    const afterHooksState = runAfterActionHooks(afterActionHooks[type], newState);

    runAsyncAfterActionHooks(asyncAfterActionHooks[type], afterHooksState)
        .then(() => console.info('finished async hooks'))
        .catch(err => console.error(`error running async hooks: ${err}`));

    return persist(afterHooksState);
};
