'use strict';

import React from 'react';

export const StaticPage = ({ page, children }) => {
    const wrapperClasses = `static-page ${page}-page`;

    return (
        <div className={wrapperClasses}>
            {children}
        </div>
    );
};
