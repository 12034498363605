'use strict';

import React, { useContext } from 'react';

import get from 'lodash/get';

import { OrderContext } from '../../order-reducer/context';

import { AddressAutocomplete } from './address-autocomplete';
import { DeliveryLocation } from './delivery-location';

export const AddressSelector = () => {
    const { state } = useContext(OrderContext);

    const isGeoCoded = Boolean(get(state, 'steps.address.geoCoded'));
    const location = get(state, 'steps.address.geoCoded.label');

    return isGeoCoded ? <DeliveryLocation location={location} /> : <AddressAutocomplete location={location} />;
};
