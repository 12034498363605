'use strict';

import React, { useContext, useEffect } from 'react';
import get from 'lodash/get';

import useRouter from 'use-react-router';

import { HashRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router';

import { ScrollControl } from './components/scroll-control';

import { Sitemap } from './sitemap';
import { Actions } from './order-reducer/actions';
import { OrderContext, OrderContextProvider } from './order-reducer/context';
import { ApplicationLayout } from './layouts/application';

export function findPageByLocation(locationPath) {
    return Sitemap.find(({ path }) => path === locationPath);
}

export const ApplicationContent = () => {
    const { dispatch } = useContext(OrderContext);
    const { history, location } = useRouter();

    // fetch products
    useEffect(() => {
        dispatch({
            type: Actions.fetchProducts,
            payload: { dispatch: dispatch },
        });
    }, []);

    // listen to location changes
    useEffect(() => {
        history.listen((location, action) => {
            dispatch({ type: Actions.locationChanged, payload: { location, action } });
        });
    }, []);

    const currentPage = findPageByLocation(location.pathname);
    const Layout = get(currentPage, 'layout', ApplicationLayout);

    return (
        <Layout>
            <Switch>
                {Sitemap.map(({ path, component, pageName, exact }) => (
                    <Route path={path} component={component} exact={exact} key={pageName} />
                ))}
            </Switch>
        </Layout>
    );
};

export const Application = () => {
    return (
        <React.StrictMode>
            <Router>
                <ScrollControl>
                    <OrderContextProvider>
                        <ApplicationContent />
                    </OrderContextProvider>
                </ScrollControl>
            </Router>
        </React.StrictMode>
    );
};
