'use strict';
import React from 'react';

import logo from '../../../assets/images/logo-vegan-food-delivery.svg';
import { Link } from 'react-router-dom';

import { ShoppingCartLink } from './shopping-cart-link';

export const Navigation = () => {
    return (
        <div className="main-navigation">
            <section className="section">
                <div className="container">
                    <div className="brand">
                        <Link to="/" className="logo">
                            <img src={logo} alt="delishios logo" />
                        </Link>

                        <div className="navigation-menu">
                            <ShoppingCartLink />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
