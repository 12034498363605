'use strict';

import React from 'react';

export const Icon = ({ icon }) => {
    return (
        <span className="icon">
            <i className={icon} />
        </span>
    );
};
