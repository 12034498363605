'use strict';

import React, { useState, useRef, useContext, useEffect } from 'react';
import debounce from 'lodash/debounce';

import { geocodeLocation, geoSuggestions, normalizeGeocoderResponse } from '../../../api/geo-coding/here-api';

import { isInDeliveryArea } from '../../../domain/address';
import { AddressSuggestions } from './address-suggestions';
import { Actions } from '../../../order-reducer/actions';
import { OrderContext } from '../../../order-reducer/context';

export function hasSuggestions(suggestions) {
    return Array.isArray(suggestions) && suggestions.length > 0;
}

export function extractLocationId(e) {
    return e.target.dataset.location;
}

export function findCurrentIndex(suggestions, active) {
    return suggestions.findIndex(({ locationId }) => locationId === active);
}

export function nextIndex(suggestions, active) {
    if (!active) {
        return 0;
    }

    const currentIndex = findCurrentIndex(suggestions, active);

    return currentIndex + 1 > suggestions.length - 1 ? 0 : currentIndex + 1;
}

export function previousIndex(suggestions, active) {
    if (!active) {
        return suggestions.length - 1;
    }

    const currentIndex = findCurrentIndex(suggestions, active);

    return currentIndex - 1 < 0 ? suggestions.length - 1 : currentIndex - 1;
}

export const AddressAutocomplete = ({ location }) => {
    const { state, dispatch } = useContext(OrderContext);

    const [inputValue, setInputValue] = useState(location);
    const [suggestions, setSuggestions] = useState([]);
    const [active, setActive] = useState(null);

    let blurTimeout = null;

    const inputRef = useRef();

    useEffect(() => {
        return () => {
            blurTimeout && clearTimeout(blurTimeout);
        };
    });

    const selectHandler = selection => {
        dispatch({ type: Actions.putAddress, payload: selection });
    };

    const selectLocation = locationId => {
        if (!locationId) {
            return null;
        }

        geocodeLocation(locationId)
            .then(({ status, data }) => {
                if (status === 200 && data) {
                    const geocoderResponse = normalizeGeocoderResponse(data);
                    const { city, country, label } = geocoderResponse;

                    if (isInDeliveryArea(city, country)) {
                        inputRef.current.value = label;
                        setInputValue(label);
                        clearSuggestions();

                        selectHandler && selectHandler(geocoderResponse);
                    } else {
                        // TODO: handle error and communicate to user
                    }
                }
            })
            .catch(err => console.error(err));
    };

    const inputHandler = value => {
        setInputValue(value);

        if (value.length < 3) {
            return null;
        }

        geoSuggestions(value)
            .then(({ status, data: { suggestions } }) => {
                if (status === 200 && suggestions) {
                    setSuggestions(suggestions);
                }
            })
            .catch(err => console.error(err));
    };

    const debouncedInputHandler = debounce(inputHandler, 200);

    const handleInput = e => {
        const value = e.target.value;

        debouncedInputHandler(value);
    };

    const handleSelect = e => {
        selectLocation(extractLocationId(e));
    };

    const handleBlur = e => {
        e.preventDefault();
        blurTimeout = setTimeout(clearSuggestions, 200);
    };

    const clearSuggestions = () => {
        setSuggestions([]);
        setActive(null);
    };

    const selectSuggestion = newIndexFunction => {
        if (!suggestions) {
            return null;
        }

        const newIndex = newIndexFunction(suggestions, active);
        const newActive = suggestions[newIndex].locationId;

        if (!newActive) {
            return null;
        }

        setActive(newActive);
    };

    const handleKeyDown = e => {
        const eventCode = e.key || e.keyCode;

        if (!hasSuggestions(suggestions)) {
            return;
        }

        switch (eventCode) {
            case 'ArrowDown':
            case 40:
                selectSuggestion(nextIndex);
                break;
            case 'ArrowUp':
            case 38:
                selectSuggestion(previousIndex);
                break;
            case 'Escape':
            case 27:
                clearSuggestions();
                break;
            case 'Enter':
            case 13:
                selectLocation(active);
                break;
        }
    };

    return (
        <div className="geo-suggest field">
            <div className="geo-suggest__input">
                <div className="field">
                    <div className="control is-expanded has-icons-left">
                        <input
                            type="text"
                            name="location"
                            id="location"
                            className="input"
                            ref={inputRef}
                            onInput={handleInput}
                            onKeyDown={handleKeyDown}
                            onBlur={handleBlur}
                            placeholder="start typing your delivery address here ..."
                            defaultValue={inputValue}
                            maxLength={150}
                        />
                        <span className="icon is-small is-left">
                            <i className={`far fa-map-marker-alt`} />
                        </span>
                    </div>
                </div>

                <AddressSuggestions suggestions={suggestions} active={active} handleSelect={handleSelect} setActive={setActive} />
            </div>
        </div>
    );
};
