'use strict';

import React from 'react';

import { formatPrice, totalFormattedPrice, totalFormattedVAT } from '../../domain/price';
import { LineItem } from './line-item';
import { InfoBox } from '../../components/infobox';

export const OrderSummary = ({ items, products }) => {
    const hasItems = products && Object.keys(items).length > 0;

    if (!hasItems) {
        return <NullOrder />;
    }

    const totalPrice = totalFormattedPrice(items, products);
    const totalVat = totalFormattedVAT(items, products);
    const totalDelivery = formatPrice(0);

    return (
        <div className="order-summary">
            <h2 className="has-text-centered">Order</h2>
            <p className="has-text-centered">An overview of what will be delivered to you.</p>

            <table className="table is-fullwidth is-striped">
                <tbody>
                    {Object.keys(items).map(key => {
                        const amount = items[key];
                        const id = parseInt(key);
                        const item = products.find(item => item.id === id);

                        return <LineItem amount={amount} item={item} key={id} />;
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td className="content has-text-right">
                            <p>
                                Delivery:&nbsp;
                                <span className="has-text-weight-bold">{totalDelivery}</span>
                            </p>
                            <p>
                                includes VAT (5%):&nbsp;
                                <span className="has-text-weight-bold">{totalVat}</span>
                            </p>
                            <p className="content is-large">
                                Total:&nbsp;
                                <span className="has-text-weight-bold">{totalPrice}</span>
                            </p>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export const NullOrder = () => (
    <div className="order-summary">
        <h2 className="has-text-centered">Order</h2>
        <InfoBox>
            <p>You have no items in your shopping cart! 😱</p>
        </InfoBox>
    </div>
);
