/*eslint no-unused-vars: 0 */
import 'babel-polyfill';

import React from 'react';
import { render } from 'react-dom';

import * as Sentry from '@sentry/browser';

const host = location.hostname;

const environment = host === 'delishios.com' ? 'production' : 'development';

Sentry.init({
    environment,
    dsn: 'https://75bed071b03e4684a50b31481ea3adce@sentry.io/1418893',
});

import { Application } from './application';

document.addEventListener('DOMContentLoaded', async () => {
    const element = document.querySelector('#application');
    render(<Application />, element);
});
