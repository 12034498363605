import cloneDeep from 'lodash/cloneDeep';

import { isInDeliveryArea, validateForm } from './address';

const hasCartItems = state => {
    const {
        cart: { items },
    } = state;

    return (
        Object.keys(items).reduce((count, itemId) => {
            return count + items[itemId];
        }, 0) > 0
    );
};

export const validateMenuStep = state => {
    return hasCartItems(state);
};

export const validateAddressStep = state => {
    const { form, config } = state.steps.address;
    const { city, country } = form;

    const errors = validateForm(form, config);
    const isAddressValid = Object.keys(errors).length === 0;

    const inDeliveryArea = isInDeliveryArea(city, country);

    return isAddressValid && inDeliveryArea;
};

export const validateOrderStep = state => {
    const { acceptTos, subscribeNewsletter, dispatchOrder } = state.cart;

    return validateAddressStep(state) && Boolean(acceptTos && subscribeNewsletter && dispatchOrder);
};

const validationStepMapping = {
    menu: validateMenuStep,
    address: validateAddressStep,
    order: validateOrderStep,
};

export function isStepValid(state, step) {
    const {
        steps: { config },
    } = state;

    const { validation: stepValidation } = config[step];

    return Boolean(stepValidation && stepValidation.valid);
}

export function isOrderValid(state) {
    const {
        cart: { acceptTos },
    } = state;

    return Boolean(isStepValid(state, 'menu') && isStepValid(state, 'address') && acceptTos);
}

export const validateOrder = state => {
    const { config } = state.steps;

    const newConfig = Object.keys(config).reduce((newConfig, id) => {
        const validator = validationStepMapping[id];

        let isValid = false;

        if (validator) {
            isValid = validator(state);
            if (isValid && Array.isArray(newConfig[id].validation.required)) {
                isValid = newConfig[id].validation.required.every(step => newConfig[step].validation.valid === true);
            }

            newConfig[id] = {
                ...cloneDeep(newConfig[id]),
                validation: {
                    ...newConfig[id].validation,
                    valid: isValid,
                },
            };
        }

        return newConfig;
    }, cloneDeep(config));

    return {
        ...cloneDeep(state),
        steps: {
            ...cloneDeep(state.steps),
            config: newConfig,
        },
    };
};
