'use strict';

import React, { useContext } from 'react';

import { OrderContext } from '../../order-reducer/context';
import { Actions } from '../../order-reducer/actions';

import { Icon } from '../icon';

export const DeliveryLocation = ({ location }) => {
    const { state, dispatch } = useContext(OrderContext);

    const handleChangeClick = e => {
        e.preventDefault();

        dispatch({ type: Actions.resetAddress });
    };

    return (
        <div className="delivery-location">
            <Icon icon="far fa-shipping-fast" /> <span className="delivery-location__address">{location}</span>
            <a href="#" className="delivery-location__change-link" onClick={handleChangeClick}>
                (change)
            </a>
        </div>
    );
};
