'use strict';

import React from 'react';

export const BulmaRequired = ({ required }) => required && <span className="is-required">*</span>;

export const BulmaLabel = ({ labelText, labelFor, required }) =>
    labelText && (
        <label htmlFor={labelFor} className="label">
            {labelText}
            <BulmaRequired required={required} />
        </label>
    );

export const BulmaField = ({ children, labelText, labelFor, required, className }) => (
    <div className={`field ${className}`}>
        <BulmaLabel labelFor={labelFor} labelText={labelText} required={required} />
        {children && children}
    </div>
);
