'use strict';

import React, { useContext } from 'react';

import { OrderContext } from '../../order-reducer/context';
import { Actions } from '../../order-reducer/actions';
import { Link } from 'react-router-dom';

export const ConfirmationForm = () => {
    const { state, dispatch } = useContext(OrderContext);

    const { cart } = state;
    const { acceptTos, subscribeNewsletter } = cart;

    const handleAcceptTos = e => {
        const value = e.currentTarget.checked;

        dispatch({ type: Actions.setAcceptTos, payload: value });
    };

    const handleSubscribeNewsletter = e => {
        const value = e.currentTarget.checked;

        dispatch({ type: Actions.setSubscribeNewsletter, payload: value });
    };

    return (
        <div className="order-confirmation content">
            <h2 className="has-text-centered">Confirmation</h2>
            <p className="has-text-centered">Legal stuff we need you to accept and the option to subscribe to our newsletter.</p>

            <div className="form-wrapper">
                <div className="field">
                    <input
                        type="checkbox"
                        name="acceptTos"
                        id="acceptTos"
                        className="is-checkradio has-background-color"
                        checked={acceptTos}
                        onChange={handleAcceptTos}
                    />
                    <label htmlFor="acceptTos">
                        I accept the <Link to="/legal#privacy">Privacy Policy</Link> and <Link to="/legal#tos">Terms of Service</Link> of delishios.
                        <span className="is-required">*</span>
                    </label>
                </div>

                <div className="field">
                    <input
                        type="checkbox"
                        name="subscribeNewsletter"
                        id="subscribeNewsletter"
                        className="is-checkradio has-background-color"
                        checked={subscribeNewsletter}
                        onChange={handleSubscribeNewsletter}
                    />
                    <label htmlFor="subscribeNewsletter">Subscribe me to the Delishios newsletter. I consent to be contacted by Email.</label>
                </div>
            </div>
        </div>
    );
};
