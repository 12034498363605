'use strict';

import React, { useState } from 'react';
import range from 'lodash/range';

import { formatPrice } from '../domain/price';
import { Icon } from './icon';

const itemRange = range(0, 31);

export const AmountSelect = ({ amount, changeHandler }) => (
    <span className="field">
        <span className="control">
            <span className="select">
                <select name="new-amount" id="new-amount" onChange={changeHandler} defaultValue={amount}>
                    {itemRange.map(i => (
                        <option key={i} value={i}>
                            {i}
                        </option>
                    ))}
                </select>
            </span>
        </span>
    </span>
);

export const DirectAmountControl = ({ amount, setCartItemAmount }) => {
    const handleAmountChange = e => {
        e.preventDefault();
        const newValue = parseInt(e.target.value);
        setCartItemAmount(newValue);
    };

    const handleRemoveFromCartClick = e => {
        e.preventDefault();
        setCartItemAmount(0);
    };

    return (
        <div className="menu-item-amount-control">
            <span className="menu-item-amount-control__dropdown">
                <AmountSelect amount={amount} changeHandler={handleAmountChange} />
            </span>

            <button onClick={handleRemoveFromCartClick} className="menu-item-amount-control__remove button">
                <Icon icon="fa fa-trash" />
            </button>
        </div>
    );
};

export const AmountControl = ({ name, amount, price, setCartItemAmount, closeHandler }) => {
    const [newAmount, setNewAmount] = useState(amount || 1);

    const handleNewAmountChange = e => {
        const newValue = parseInt(e.target.value);
        setNewAmount(newValue);
    };

    const handleRemoveFromCartClick = e => {
        e.preventDefault();
        setCartItemAmount(0);
        closeHandler && closeHandler();
    };

    const handleAddToCartClick = e => {
        e.preventDefault();
        setCartItemAmount(newAmount);
        closeHandler && closeHandler();
    };

    const totalPrice = formatPrice(price * newAmount);
    const buttonText = `order for ${totalPrice}`;
    const isButtonEnabled = newAmount !== 0;

    return (
        <div className="menu-item-amount-control">
            <span className="menu-item-amount-control__dropdown">
                <AmountSelect amount={newAmount} changeHandler={handleNewAmountChange} />
            </span>

            <span className="menu-item-amount-control__button">
                {amount > 0 && newAmount === 0 ? (
                    <button aria-label={buttonText} className="button is-danger" onClick={handleRemoveFromCartClick}>
                        <span>remove from cart</span>
                    </button>
                ) : (
                    <React.Fragment>
                        <button aria-label={buttonText} disabled={!isButtonEnabled} className={`button ${isButtonEnabled && 'is-primary'}`} onClick={handleAddToCartClick}>
                            <span>{buttonText}</span>
                        </button>
                        {amount > 0 && (
                            <button onClick={handleRemoveFromCartClick} className="button menu-item-amount-control__remove">
                                <Icon icon="fa fa-trash" />
                            </button>
                        )}
                    </React.Fragment>
                )}
            </span>
        </div>
    );
};
