'use strict';

import React, { useState } from 'react';

export const BulmaTextAreaControl = ({ name, placeholder, value, errors, validateForm, updateForm, required = true }) => {
    return (
        <div className="control">
            <textarea
                aria-label={name}
                className="textarea"
                name={name}
                id={name}
                placeholder={`${placeholder} ...`}
                value={value}
                onChange={updateForm}
                onBlur={validateForm}
                required={required}
                maxLength={300}
            />
        </div>
    );
};

export const BulmaInputControl = ({ name, placeholder, value, errors, validateForm, updateForm, type = 'text', required = true, iconLeft = null }) => {
    const [touched, setTouched] = useState(false);

    let iconRight,
        validationState,
        validationIcon = null;

    if (touched) {
        iconRight = errors ? 'exclamation-triangle' : 'check-circle';
        validationState = errors ? 'is-danger' : 'is-success';
        validationIcon = errors ? 'has-text-danger' : 'has-text-success';
    }

    const handleTouch = (e, handler) => {
        setTouched(true);
        handler(e);
    };

    const inputClassName = ['control', iconLeft ? 'has-icons-left' : '', iconRight ? 'has-icons-right' : ''].join(' ');

    return (
        <div className={inputClassName}>
            <input
                aria-label={name}
                type={type}
                className={`input ${validationState}`}
                name={name}
                id={name}
                placeholder={`${placeholder} ...`}
                value={value}
                onChange={e => handleTouch(e, updateForm)}
                onBlur={e => handleTouch(e, validateForm)}
                required={required}
                maxLength={150}
            />
            {iconLeft && (
                <span className="icon is-small is-left">
                    <i className={`fas fa-${iconLeft}`} />
                </span>
            )}
            {iconRight && (
                <span className={`icon is-small is-right ${validationIcon}`}>
                    <i className={`fas fa-${iconRight}`} />
                </span>
            )}
        </div>
    );
};

export const BulmaSelectControl = ({ name, value, children, validateForm, updateForm }) => {
    return (
        <div className="field">
            <div className="control">
                <div className="select">
                    <select aria-label={name} name={name} id={name} value={value} onChange={updateForm} onBlur={validateForm}>
                        {children}
                    </select>
                </div>
            </div>
        </div>
    );
};
