'use strict';

import React, { useReducer, createContext } from 'react';

import merge from 'lodash/merge';

import { initialOrderState } from './state';
import { load } from '../persistence';
import { OrderReducer } from './reducer';

export const OrderContext = createContext();

export const OrderContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(OrderReducer, merge(initialOrderState, load()));
    const value = { state, dispatch };

    return (
        <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
    );
};
