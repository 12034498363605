'use strict';

import React from 'react';
import useRouter from 'use-react-router';

import { Navigation } from '../layout/navigation';
import { Footer } from '../layout/footer';

import { mainContentClassForLocaction } from './helpers';

export const ApplicationLayout = ({ children }) => {
    const { location } = useRouter();

    return (
        <React.Fragment>
            <Navigation />

            <section className={mainContentClassForLocaction(location)}>
                <div className="container">{children}</div>
            </section>

            <Footer />
        </React.Fragment>
    );
};
