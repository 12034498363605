'use strict';

import React from 'react';

export const DeliveryThankYouPage = () => {
    return (
        <div className="delivery-thank-you content">
            <h1 className="is-1 has-text-centered">Thank you for your order! 🙇‍</h1>

            <p>🤷 Delishios is not open for business yet. We are currently testing demand for vegan food delivery.</p>

            <p>If you checked the subscribe box when you ordered, we added you to our "early access" mailing list, so we can inform you when we start operations in your area.</p>

            <p>
                If you did not you can do it here:&nbsp;
                <a href="https://newsletter.delishios.com/signup" target="_blank" rel="noopener">
                    Subscribe to our "early access" Newsletter
                </a>
            </p>
            <p>You can of course unsubscribe at any time via the unsubscribe link included in the any email we send you.</p>
        </div>
    );
};
