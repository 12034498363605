import { EmailRegexp } from '../../domain/validations/validations';

export const initialState = {
    form: {
        name: '',
        email: '',
        city: '',
        zip: '',
        country: 'Cyprus',
        favorites: '',
        acceptTos: false,
    },
    config: {
        name: {
            required: { presence: true },
            length: { min: 4 },
        },
        email: {
            required: { presence: true },
            length: { min: 4 },
            match: { pattern: EmailRegexp },
        },
        city: {
            required: { presence: false },
        },
        zip: {
            required: { presence: false },
        },
        country: {
            required: { presence: true },
            length: { min: 4 },
        },
        favorites: {
            required: { presence: false },
        },
        acceptTos: {
            required: { presence: true },
            equals: {
                expectation: true,
            },
        },
    },
    state: 'edit',
    validation: {
        errors: {},
    },
};
